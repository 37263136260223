<template>
    <div id="" class="container max-w-4xl mx-auto pt-20 pb-20 px-6">
        <div class="text-gray-900 text-xl">Inserisci il codice ricevuto dall'assistente</div>
        <div class="bg-green-500 w-full h-1"></div>

        <div class="flex flex-wrap mt-4 mb-6">
            <div class="w-full md:w1/2 px-3 mb-3">
                <TextInput label="Codice" placeholder="XXXXXXX" v-model:input="codice" inputType="text"
                    :error="errors ? errors : ''" />
            </div>
        </div>

        <div class="flex flex-wrap mt-8 mb-6">
            <div class="w-full px-3">
                <SubmitFormButton btnText="Conferma" @submit="ricercaVotoManuale" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import TextInput from '../../components/global/TextInput.vue'
import SubmitFormButton from '../../components/global/SubmitFormButton.vue'
import axios from 'axios';
import { useRouter } from 'vue-router';

const router = useRouter()

let codice = ref(null)
let errors = ref()

const ricercaVotoManuale = async () => {
    errors.value = '';

    let data = new FormData();

    data.append('codice', codice.value || '')

    try {
        let res = await axios.get(`api/getID/${codice.value}`);
        if (res.status === 200) {
            router.push(`/vota-pizza/${res.data.id}`);
        }
        else 
        {
            errors.value = "Codice errato, ripetere l'immissione."
        }
    }
    catch (err) {
        errors.value = "Codice errato, ripetere l'immissione."
        //errors.value = err.response.data.errors;
    }

}
</script>