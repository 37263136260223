<template>
    <div id="" class="container max-w-4xl mx-auto pt-5 pb-20 px-6">
        <div class="flex justify-between items-center pt-1">
            <div class="text-gray-900 text-xl">
                Voti
            </div>

            <input v-model="search" class="border border-gray-300 rounded-md p-2 mr-2" type="search" placeholder="Cerca Concorrente...">
        </div>

        <div class="bg-red-500 w-full h-1 my-4"></div>
        <div v-if="voti">
            <div class="flex py-2 font-bold items-center">
                <div class="w-1/5">
                    Short Code
                </div>

                <div class="w-2/5">
                    Concorrente
                </div>

                <div class="w-2/5">
                    Giudice
                </div>

                <div class="w-2/5">
                    Nome Concorso
                </div>

                <div class="w-1/5 text-right">
                    Azione
                </div>
            </div>

            <div v-for="voto in voti" :key="voto.id" id="elenco-voti" class="flex py-2 items-center">

                <div class="w-1/5">
                    {{ voto.concorso_cliente.cliente.shortCode }}
                </div>

                <div class="w-2/5">
                    {{ voto.concorso_cliente.cliente.cognome }} {{ voto.concorso_cliente.cliente.nome }}
                </div>

                <div class="w-2/5">
                    {{ voto.user.last_name }} {{ voto.user.first_name }}
                </div>

                <div class="w-2/5">
                    {{ voto.concorso_cliente.concorso.nome_concorso }}
                </div>

                <div class="w-1/5 text-right">
                    <button class="mr-4" @click="cancellaVoto(voto.id)">
                        <i class="fas fa-trash fa-1x mr-4"></i>
                    </button>

                </div>
            </div>

            <div class="bg-red-500 w-full h-1 my-4"></div>
            <div v-if="totalPages > 1">
                <div class="pagination">
                    <button class="mr-4" v-for="page in totalPages" :key="page" :disabled="page === currentPage"
                        @click="getVoti(page)">
                        {{ page }}
                    </button>
                </div>
            </div>
        </div>
        <div class="w-full pl-4" v-else>
            Caricamento in corso...
        </div>
    </div>
</template>

<style>
#elenco-voti {
    background-color: #fff;
}

#elenco-voti:nth-child(even) {
    background-color: #f2f2f2;
}
</style>

<script setup>
import axios from 'axios'
import $wal from '../../sweetalert2'
import { onMounted, ref, watch } from 'vue'
import { debounce } from 'lodash'

const voti = ref([])
let totalPages = ref(0)
let search = ref('')
let currentPage = ref(1)

async function searchVoti() {

    const response = await axios.get(`/api/voti/search?filtro=${search.value}`);
    voti.value = response.data.paginate.data
    totalPages.value = response.data.page_count
}

watch(search, debounce((value) => {
    if (value.length > 3) {
        searchVoti()
    } else {
        getVoti(1)
    }
}, 300))

function getVoti(page) {
    currentPage.value = page
    axios.get('/api/voti', {
        params: {
            page
        }
    })
        .then(res => {
            voti.value = res.data.paginate.data
            totalPages.value = res.data.page_count
        })
}

function cancellaVoto(id) {
    $wal.fire({
        title: "Sei sicuro di voler eliminare questo voto?",
        text: 'Si tratta di un\'operazione irreversibile!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: "Si, procedi!",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: '#d33',
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                await axios.delete('api/voti/' + id);

                getVoti(currentPage);

                $wal.fire(
                    'Cancellato!',
                    'Il voto è stato correttamente eliminato.',
                    'success'
                )
            } catch (err) {
                console.log(err);
            }
        }
    })
}

onMounted(() => {
    getVoti(1)
})
</script>

<style>
button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination {
    display: flex;
}

.pagination button {
    border: 1px solid #ccc;
    padding: 8px 12px;
    margin: 0 4px;
    border-radius: 4px;
}

.pagination button:first-child {
    margin-left: 0;
}

.pagination button:last-child {
    margin-right: 0;
}
</style>