<template>
    <div id="CenteCallToAction" class="max-w-4xl">
        <div class="px-4 md:w-3/4 lg:w-2/3 mx-auto text-center">
            <h1 class="text-4xl sm:text-5xl md:text-5xl leading-tight text-center text-gray-100 mb-3">
                <b>Pizza DOC <br> World Championship</b>
            </h1>
            <p class="pt-2 text-xl text-center text-gray-200 font-bold">
                13/14/15 Novembre 2023
            </p>
            <p class="py-2 text-xl text-center text-gray-300">
                Partecipa all'evento...
            </p>
        </div>
        <div class="mt-6"></div>
        <div class="flex justify-between">
            <router-link class="
                btn-standard
                w-full
                mr-1
                py-2
                px-4
                bg-green-600
                text-white
                text-center
                font-semibold
                text-2xl
                rounded-lg
                shadow-md
                cursor-pointer" 
                to="login"
            >
                Login
            </router-link>

        </div>
    </div>
    <div id="backDarkOverlay"></div>

    <img src="" alt="">

</template>

<script setup>
</script>

<style lang="scss">
#CenteCallToAction {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

#myImage {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -2;
    background-color: #00153B;
}

#backDarkOverlay {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    background-color: #00153b;
}
</style>