<template>
    <div id="" class="container max-w-4xl mx-auto pt-5 pb-20 px-6">
        <div class="flex justify-between items-center pt-1">
            <div class="text-gray-900 text-xl">
                Voti
            </div>
        </div>

        <div class="bg-red-500 w-full h-1 my-4"></div>
 
        <div v-if="voti">
            <div class="scroll-container">
                <div class="table-wrapper">
                    <table>
                        <thead>
                            <tr>
                                <th style="width: 280px;">Giudice</th>
                                <th style="width: 140px;">Data/Ora Voto</th>
                                <th style="width: 100px;" class="text-right">Aspetto</th>
                                <th style="width: 100px;" class="text-right">Cottura</th>
                                <th style="width: 100px;" class="text-right">Gusto</th>
                                <th style="width: 280px;">Concorso</th>
                                <th style="width: 280px;">Concorrente</th>
                                <th style="width: 100px;" class="text-center">Azione</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(voto, index) in voti" :key="voto.id" :class="{ 'bg-even': index % 2 === 0 }">
                                <td style="width: 200px;">{{ voto.user.last_name }} {{ voto.user.first_name }}</td>
                                <td style="width: 140px;">{{ formatDate(voto.created_at) }}</td>
                                <td style="width: 100px;" class="text-right">{{ voto.voto_aspetto }}</td>
                                <td style="width: 100px;" class="text-right">{{ voto.voto_cottura }}</td>
                                <td style="width: 100px;" class="text-right">{{ voto.voto_gusto }}</td>
                                <td style="width: 280px;">{{ voto.concorso_cliente.concorso.shortCode }} - {{ voto.concorso_cliente.concorso.nome_concorso }}</td>
                                <td style="width: 280px;">{{ voto.concorso_cliente.cliente.shortCode }} - {{ voto.concorso_cliente.cliente.cognome }} {{
                                    voto.concorso_cliente.cliente.nome }}</td>
                                <td style="width: 100px;">
                                    <button class="mr-4 text-center" @click="cancellaVoto(voto.id)">
                                        <i class="fas fa-trash fa-1x mr-4"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="w-full pl-4" v-else>
            Caricamento in corso...
        </div>
    </div>
</template>

<style>
#elenco-voti {
    background-color: #fff;
}

#elenco-voti:nth-child(even) {
    background-color: #f2f2f2;
}
</style>

<script setup>
import axios from 'axios'
import $wal from '../../sweetalert2'
import { onMounted, ref, watch } from 'vue'
import { debounce } from 'lodash'
import { useRoute } from 'vue-router'

const formatDate = (date) => {
    const dateObj = new Date(date);
    const day = dateObj.getDate().toString().padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObj.getFullYear();
    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    const seconds = dateObj.getSeconds().toString().padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

const voti = ref([])
let totalPages = ref(0)
let search = ref('')
let currentPage = ref(1)
const route = useRoute();
const id = ref(route.params.id);

async function searchVoti() {
    const response = await axios.get(`/api/voti/search?filtro=${search.value}`);
    voti.value = response.data.paginate.data
    totalPages.value = response.data.page_count
}

watch(search, debounce((value) => {
    if (value.length > 3) {
        searchVoti()
    } else {
        getVoti(1)
    }
}, 300))

function getVoti(page) {
    currentPage.value = page
    axios.get(`/api/voti/${id.value}`, {
        params: {
            page
        }
    })
        .then(res => {
            voti.value = res.data.paginate.data
            totalPages.value = res.data.page_count
        })
}

function cancellaVoto(id) {
    $wal.fire({
        title: "Sei sicuro di voler eliminare questo voto?",
        text: 'Si tratta di un\'operazione irreversibile!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: "Si, procedi!",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: '#d33',
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                await axios.delete('api/voti/' + id);

                getVoti(currentPage);

                $wal.fire(
                    'Cancellato!',
                    'Il voto è stato correttamente eliminato.',
                    'success'
                )
            } catch (err) {
                console.log(err);
            }
        }
    })
}

onMounted(() => {
    getVoti(1)
})
</script>

<style>
button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination {
    display: flex;
}

.pagination button {
    border: 1px solid #ccc;
    padding: 8px 12px;
    margin: 0 4px;
    border-radius: 4px;
}

.pagination button:first-child {
    margin-left: 0;
}

.pagination button:last-child {
    margin-right: 0;
}
</style>