<template>
    <div class="container max-w-4xl mx-auto flex mt-10">
        <div class="w-full pl-4 mb-6" v-if="cliente">
            <div class="flex">
                <div class="w-2/3">
                    <h1 class="text-2xl md:text-4xl text-left text-gray-900">
                        {{ cliente.cognome }} {{ cliente.nome }}
                    </h1>
                    <div class="text-md text-gray-700">
                        Email: <i><b>{{ cliente.email }}</b></i>
                    </div>
                    <div class="text-md text-gray-700">
                        Telefono: <i><b>{{ cliente.telefono }}</b></i>
                    </div>
                    <div class="text-md text-gray-700">
                        Taglia: <i><b>{{ cliente.taglia }}</b></i>
                    </div>
                    <div class="text-md text-gray-700">
                        ShortCode: <i><b>{{ cliente.shortCode }}</b></i>
                    </div>
                    <div class="text-md text-gray-700">
                        Luogo di lavoro: <i><b>{{ cliente.luogo_lavoro }}</b></i>
                    </div>
                    <div class="flex">
                        <div class="w-1/2 mt-3 mb-3">
                            <div class="text-gray-900 text-md mb-2">
                                Nazionalità (lavoro):
                            </div>
                            <vue-select v-model="selNazione" :options="optionsNazione" :reduce="option => option.value"
                                placeholder="" @search="handleSearch"></vue-select>
                        </div>

                        <div id="" class="w-1/2 mt-3 mb-3 px-3">
                            <div class="text-gray-900 text-md mb-2">
                                Eta:
                            </div>
                            <select
                                class="w-1/3 px-3 py-2 rounded-md bg-gray-50 border border-gray-300 text-sm shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                                v-model="eta">
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                                <option value="32">32</option>
                                <option value="33">33</option>
                                <option value="34">34</option>
                                <option value="35">35</option>
                                <option value="36">36</option>
                                <option value="37">37</option>
                                <option value="38">38</option>
                                <option value="39">39</option>
                                <option value="40">40</option>
                                <option value="41">41</option>
                                <option value="42">42</option>
                                <option value="43">43</option>
                                <option value="44">44</option>
                                <option value="45">45</option>
                                <option value="46">46</option>
                                <option value="47">47</option>
                                <option value="48">48</option>
                                <option value="49">49</option>
                                <option value="50">50</option>
                                <option value="51">51</option>
                                <option value="52">52</option>
                                <option value="53">53</option>
                                <option value="54">54</option>
                                <option value="55">55</option>
                                <option value="56">56</option>
                                <option value="57">57</option>
                                <option value="58">58</option>
                                <option value="59">59</option>
                                <option value="60">60</option>
                                <option value="61">61</option>
                                <option value="62">62</option>
                                <option value="63">63</option>
                                <option value="64">64</option>
                                <option value="65">65</option>
                                <option value="66">66</option>
                                <option value="67">67</option>
                                <option value="68">68</option>
                                <option value="69">69</option>
                                <option value="70">70</option>
                                <option value="71">71</option>
                                <option value="72">72</option>
                                <option value="73">73</option>
                                <option value="74">74</option>
                                <option value="75">75</option>
                                <option value="76">76</option>
                                <option value="77">77</option>
                                <option value="78">78</option>
                                <option value="79">79</option>
                                <option value="80">80</option>
                                <option value="81">81</option>
                                <option value="82">82</option>
                                <option value="83">83</option>
                                <option value="84">84</option>
                                <option value="85">85</option>
                                <option value="86">86</option>
                                <option value="87">87</option>
                                <option value="88">88</option>
                                <option value="89">89</option>
                                <option value="90">90</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="w-1/3">
                    <div class="font-bold text-gray-100 rounded text-center bg-green-500 p-2 py-1 text-sm h-8 items-center">
                        Concorsi {{ cliente.concorsi.length }}
                    </div>
                    <div class="grid grid-cols-2 gap-4 items-center mt-4">
                        <div class="font-bold text-gray-900">Accreditato</div>
                        <div>
                            <VueToggles :value="cliente.accreditato" :height="30" :width="90" checkedText="Si"
                                uncheckedText="No" checkedBg="#22c55e" uncheckedBg="#ef4444" @click="toggleAccredito" />
                        </div>
                        <div class="col-span-2 text-right">
                            <button class="button primary-button w-full" @click="openPopupConcorsi">Aggiungi
                                Concorso</button>
                        </div>
                        <div class="text-right">
                            <button class="button primary-button" @click="badgeHTML">Stampa Badge</button>
                        </div>
                        <div class="text-right" v-show="cliente.concorsi && cliente.concorsi.length > 0">
                            <button class="button primary-button" @click="printHTML">Stampa QrCode</button>
                        </div>
                    </div>

                </div>
            </div>
            <div class="flex justify-between items-center pt-1 mt-4">
                <div class="text-gray-900 text-xl">
                    Concorsi
                </div>
                <div class="w-1/4 flex">
                    <select id="filtro-data"
                        class="flex-1 px-3 py-2 rounded-md bg-gray-50 border border-gray-300 text-sm shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed align-middle"
                        v-model="filtro_data">
                        <option value="lunedi-13-novembre">lunedi-13-novembre</option>
                        <option value="martedi-14-novembre">martedi-14-novembre</option>
                        <option value="mercoledi-15-novembre" selected="selected">mercoledi-15-novembre</option>
                    </select>
                </div>
            </div>
            <div class="bg-green-500 w-full h-1 my-4"></div>

            <div class="scroll-container" v-show="cliente.concorsi && cliente.concorsi.length > 0">
                <div class="table-wrapper">
                    <table>
                        <thead>
                            <tr>
                                <th style="width: 40px;"></th>
                                <th style="width: 80px;">SKU</th>
                                <th style="width: 280px;">Nome Concorso</th>
                                <th style="width: 100px;">Order ID</th>
                                <th style="width: 100px;">Stato</th>
                                <th style="width: 200px;">Data</th>
                                <th style="width: 120px;">Orario</th>
                                <th style="width: 120px;">Forno</th>
                                <th style="width: 40px;">Assistente</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(concorso, index) in cliente.concorsi" :key="concorso.id"
                                :class="{ 'bg-even': index % 2 === 0 }">
                                <td style="width: 40px;" class="text-center">
                                    <router-link
                                        :to="{ name: 'EditOrder', params: { id: concorso.pivot.id, cliente_id: concorso.pivot.cliente_id } }">
                                        <i class="fas fa-pencil fa-1x mr-4"></i>
                                    </router-link>
                                </td>
                                <td style="width: 80px;">{{ concorso.shortCode }}</td>
                                <td style="width: 280px;">{{ concorso.nome_concorso }} </td>
                                <td style="width: 100px;">{{ concorso.pivot.order_id }}</td>
                                <td style="width: 100px;">{{ concorso.pivot.status }}</td>
                                <td style="width: 200x;">{{ concorso.pivot.data }}</td>
                                <td style="width: 120px;">{{ concorso.pivot.orario }}</td>
                                <td style="width: 120px;">{{ concorso.pivot.forno }}</td>
                                <td style="width: 40px;">{{ concorso.pivot.assistente ? 'SI' : 'NO' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-show="!cliente.concorsi || cliente.concorsi.length === 0">
                Non risultano concorsi per la data selezionata...
            </div>
        </div>
        <div class="w-full pl-4" v-else>
            Caricamento in corso...
        </div>

        <transition name="popup-qrCode">
            <div v-if="isPopupQrCodeOpen" class="popup-qrCode">
                <div class="popup-qrCode-content">
                    <div id="qrCodeElement">
                        <div class="text-4xl sm:text-4xl md:text-4xl leading-tight text-center text-gray-900 ">Pizza
                            DOC<br>World Championship</div>
                        <div class="bg-gray-900 w-full h-0.5 my-3"></div>

                        <qrcode-vue :value="qrcodeValue" :size="200" :level="qrcodeLevel" :render-as="renderAs" />

                        <div class="bg-gray-900 w-full h-0.5 my-3"></div>
                        <div class="text-gray-900 text-bold text-2xl mb-3">{{ categoriaValue }}</div>
                    </div>
                    <div class="buttons">

                        <button class="button primary-button" @click="print">
                            Stampa
                        </button>

                        <button class="button secondary-button close-button" @click="closePopupQrCode">
                            <i class="fas fa-times"></i>
                        </button>

                    </div>
                </div>
            </div>
        </transition>

        <transition name="popup-concorsi">
            <div v-if="isPopupConcorsiOpen" class="popup-concorsi">
                <div v-if="concorsi" class="popup-concorsi-content">
                    <div class="flex justify-between items-center pt-1 mt-4">
                        <div class="text-gray-900 text-xl">
                            Concorsi
                        </div>
                    </div>
                    <div class="bg-green-500 w-full h-1 my-4"></div>
                    <div id="popup-concorsi-lista">
                        <div class="flex py-2 font-bold items-center">
                            <div class="w-1/6 text-right">
                                Azione
                            </div>
                            <div class="w-1/6">
                                Short Code
                            </div>

                            <div class="w-4/6">
                                Nome Concorso
                            </div>
                        </div>
                        <div v-if="concorsi && concorsi.length > 0">
                            <div v-for="concorso in concorsi" :key="concorso.id" id="elenco-concorsi-popup"
                                class="flex py-2 items-center">
                                <div class="w-1/6 text-center">
                                    <button @click="attachConcorsoToCliente(cliente.id, concorso.id)">
                                        <i class="fas fa-cart-shopping"></i>
                                    </button>
                                </div>
                                <div class="w-1/6">
                                    {{ concorso.shortCode }}
                                </div>

                                <div class="w-4/6">
                                    {{ concorso.nome_concorso }}
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            Non ci sono altri concorsi da aggiungere...
                        </div>
                    </div>
                    <button class="button secondary-button close-button" @click="closePopupConcorsi">
                        <i class="fas fa-times"></i>
                    </button>
                </div>
                <div v-else class="popup-concorsi-content">
                    Caricamento in corso...
                </div>
            </div>

        </transition>
    </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';
import $wal from '../../sweetalert2';
import { VueToggles } from "vue-toggles";
import QrcodeVue from 'qrcode.vue'
import QRCode from 'qrcode-svg'

import VueSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

const labels = ref([])

const route = useRoute();
const id = ref(route.params.id);
const cliente = ref(null);
let filtro_data = ref('mercoledi-15-novembre')
let eta = ref(0);

const optionsNazione = ref([]);
let selNazione = ref(''); //cliente.value.nazionalita || '');

const fetchCliente = async () => {
    try {
        const response = await axios.get(`/api/clienti/${id.value}/concorsi/${filtro_data.value}`);
        cliente.value = response.data;
        eta.value = cliente.value.eta;
        selNazione.value = cliente.value.nazionalita;
        console.log(cliente.value);
    } catch (error) {
        console.error(error);
    }
};

watch(() => filtro_data.value, () => {
    fetchCliente();
    fetchLabels();
})

watch(() => eta.value, async () => {
    try {
        const response = await axios.post(`api/eta/${cliente.value.id}?_method=PUT`, {
            eta: eta.value
        });

        if (response.data.id != cliente.value.id) {
            $wal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Si è verificato un errore, riprova più tardi'
            });
        }
    } catch (error) {
        console.error(error);
        $wal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Si è verificato un errore, riprova più tardi'
        });
    }
})

/* POPUP QRCODE */
const isPopupQrCodeOpen = ref(false)
let qrcodeValue = ref('')
let categoriaValue = ref('')
const qrcodeLevel = ref('M')
const renderAs = ref('svg')

const closePopupQrCode = () => {
    isPopupQrCodeOpen.value = false
}

const print = () => {
    const original = document.getElementById('qrCodeElement');

    const printWindow = window.open('', '_blank');
    const printDocument = printWindow.document;

    const stylesheets = Array.from(document.styleSheets);
    stylesheets.forEach((stylesheet) => {
        const link = printDocument.createElement('link');
        link.rel = 'stylesheet';
        link.href = stylesheet.href;
        printDocument.head.appendChild(link);
    });

    printDocument.body.innerHTML = original.outerHTML;

    printWindow.print();

    printWindow.close();
};
/* POPUP QRCODE */

/* POPUP CONCORSI */
const isPopupConcorsiOpen = ref(false)
const concorsi = ref(null);

const openPopupConcorsi = () => {
    fetchConcorsi();
    isPopupConcorsiOpen.value = true
}

const closePopupConcorsi = () => {
    concorsi.value = null;
    isPopupConcorsiOpen.value = false
}

const fetchConcorsi = async () => {
    try {
        const response = await axios.get(`/api/clienti/new-concorsi/${id.value}`);
        concorsi.value = response.data;
    } catch (error) {
        console.error(error);
    }
}

const attachConcorsoToCliente = async (clienteId, concorsoId) => {
    try {
        await axios.post(`/api/attach-concorso-cliente/${clienteId}/${concorsoId}`);
        $wal.fire(
            'Concorso aggiunto!',
            '',
            'success'
        );

        fetchCliente();
    } catch (error) {
        console.error(error);
        $wal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Si è verificato un errore, riprova più tardi'
        });
    }
}
const toggleAccredito = async () => {
    try {
        cliente.value.accreditato = !cliente.value.accreditato

        const response = await axios.post(`api/clienti/${cliente.value.id}/accreditato?_method=PUT`, {
            accreditato: cliente.value.accreditato
        });

        if (response.data.id != cliente.value.id) {
            $wal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Si è verificato un errore, riprova più tardi'
            });
        }
    } catch (error) {
        console.error(error);
        $wal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Si è verificato un errore, riprova più tardi'
        });
    }
}

/* ETICHETTE QRCODE CONCORSO */
async function printHTML() {
    const iframe = document.createElement('iframe')

    iframe.onload = async () => {
        const printSection = document.createElement('div')

        const labelsHtml = await generateLabelsHtml(labels.value)

        printSection.innerHTML = labelsHtml

        addPrintStylesheet(printSection)

        iframe.contentDocument.body.appendChild(printSection)

        iframe.contentWindow.focus()
        iframe.contentWindow.print()
    }

    iframe.style.display = 'none'
    document.body.appendChild(iframe)
    if (!cliente.value.accreditato) { toggleAccredito(); }
}

async function generateLabelsHtml(labels) {
    const htmlPromises = labels.map(async (label) => {
        const qrCode = new QRCode({
            content: `https://3wa.it/vota-pizza/${label.pivot.id}`,
            padding: 0,
            width: 110,
            height: 110,
            color: '#000000',
            background: '#ffffff',
        })

        const qrCodeSvg = qrCode.svg()
        let dataShort = label.pivot.data.split('-novembre')[0];

        return `
      <div class="label">
        <div>
          <h3 class="titolo">Pizza Doc</h3>
          <h6 class="sottotitolo">World Championship</h6>
        </div>

        <div class="qr-code">
            ${qrCodeSvg}
        </div>

        <p class="concorso" style="font-size:12px;">${label.nome_concorso}</p>
        <p class="short-code">${label.shortCode}</p>
        <p class="data-concorso">${dataShort}</p>
        <p class="forno">${label.pivot.forno}</p>
      </div>
    `
    })

    const htmls = await Promise.all(htmlPromises)

    return htmls.join('')
}

function addPrintStylesheet(element) {

    const printStyle = document.createElement('style')

    printStyle.innerHTML = `
    @media print {
        @page {
            size: 40mm 89mm;
            margin: 5mm; 
            margin-left: 3mm;
            margin-right: 3mm;
        }
        @page {
            margin-top: 0;
        }
        @page {
            margin-bottom: 0;
        }
        .label {
            page-break-after: always;
        }
        .titolo {
            text-align: center;
            margin-bottom: 1px;
            margin-top: 5px;
        }
        .sottotitolo {
            text-align: center;
            margin-top: 0px;
        }
        .qr-code {
            text-align: center;
            margin: 5px 0 5px 0;
        }
        .concorso {
            font-size: 12px;
            margin-top: 3px;
            padding-top: 6px;
        }
        .short-code {
            font-size: 10px;
            margin-top: 5px;
            padding-top: 6px;
        }
        .nota-pie {
            font-size: 9px;
            padding-top: 3px;
        }
    }
  `
    element.appendChild(printStyle)

}

async function fetchLabels() {
    try {
        const response = await axios.get(`/api/clienti/${id.value}/concorsi/${filtro_data.value}`);
        labels.value = response.data.concorsi;
    } catch (error) {
        console.error(error);
    }
}
/* ETICHETTE  QRCODE CONCORSI*/

/* ETICHETTA BADGE ACCREDITO */
async function badgeHTML() {
    const iframe = document.createElement('iframe')

    iframe.onload = async () => {
        const printSection = document.createElement('div')

        const labelsHtml = await generateBadgeHtml()

        printSection.innerHTML = labelsHtml

        addBadgeStylesheet(printSection)

        iframe.contentDocument.body.appendChild(printSection)

        iframe.contentWindow.focus()
        iframe.contentWindow.print()
    }

    iframe.style.display = 'none'
    document.body.appendChild(iframe)
    // if (!cliente.value.accreditato) { toggleAccredito(); }
}

async function generateBadgeHtml() {

    const qrCode = new QRCode({
        content: `${cliente.value.shortCode}`,
        padding: 0,
        width: 60,
        height: 60,
        color: '#000000',
        background: '#ffffff',
    })

    const qrCodeSvg = qrCode.svg()

    return `
    <link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap" rel="stylesheet">


    <div class="label" style="height: 28mm;">
                    <div class="nominativo">
                        <table style="width: 100%">
                            <tr>
                                <td>
                                    <h2 class="nome" style="font-weight:900 !important; font-family: 'Montserrat', sans-serif; font-size: 25px; line-height:1em">${cliente.value.nome.toUpperCase()}<br>
                                        ${cliente.value.cognome.toUpperCase()}</h3>
                                </td>
                                <td style="padding-left: 35px; margin-top: 15px; margin-right: 0px;">
                                    <div class="qr-code">
                                        ${qrCodeSvg}
                                    </div>  
                                </td>                                
                            </tr>
                        </table>
                    </div>
                </div>
        `
}

function addBadgeStylesheet(element) {
    const printStyle = document.createElement('style')

    printStyle.innerHTML = `
@media print {
    @page {
        size: 89mm 40mm;
        margin: 5mm; 
        margin-left: 3mm;
        margin-right: 5mm;
        margin-top: 2mm;
    }
    @page {
        margin-top: 0;
    }
    @page {
        margin-bottom: 0;
    }
    .label {
        page-break-after: always;
        display: flex;
        justify-content: center;
        align-items:center;
    }
    table {
        width: 100%;
        border-collapse: collapse;
        margin-right: 3px;
    }
    tr {
        display: flex;
    }
    td {
        flex: 1;
    }
    .nome {
        text-align: left;
        margin-bottom: 1px;
    }
    .cognome {
        text-align: left;
        margin-top: 0;
    }
    .nominativo, .qr-code {
        display: flex;
        align-items: center;
    }
}
  `
    element.appendChild(printStyle)
}
/* ETICHETTA BADGE ACCREDITO */


/* NAZIONALITA */
function populateOptionsFromData(data) {
    optionsNazione.value = data.map((item) => ({
        value: item,
        label: item
    }));
}

const dataFromArray = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antartide",
    "Antigua e Barbuda",
    "Arabia Saudita",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belau",
    "Belgio",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bielorussia",
    "Birmania",
    "Bolivia",
    "Bonaire, Saint Eustatius e Saba",
    "Bosnia-Erzegovina",
    "Botswana",
    "Brasile",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambogia",
    "Camerun",
    "Canada",
    "Capo Verde",
    "Ciad",
    "Cile",
    "Cina",
    "Cipro",
    "Colombia",
    "Comore",
    "Congo (Brazzaville)",
    "Congo (Kinshasa)",
    "Corea del Nord",
    "Corea del Sud",
    "Costa d'Avorio",
    "Costa Rica",
    "Croazia",
    "Cuba",
    "Curaçao",
    "Danimarca",
    "Dominica",
    "Ecuador",
    "Egitto",
    "El Salvador",
    "Emirati Arabi Uniti",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Etiopia",
    "Figi",
    "Filippine",
    "Finlandia",
    "Francia",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germania",
    "Ghana",
    "Giamaica",
    "Giappone",
    "Gibilterra",
    "Gibuti",
    "Giordania",
    "Grecia",
    "Grenada",
    "Groenlandia",
    "Guadalupa",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea Equatoriale",
    "Guinea-Bissau",
    "Guyana",
    "Guyana Francese",
    "Haiti",
    "Honduras",
    "Hong Kong",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Irlanda",
    "Islanda",
    "Isola Bouvet",
    "Isola di Man",
    "Isola di Natale",
    "Isola Norfolk",
    "Isole Åland",
    "Isole Cayman",
    "Isole Cocos (Keeling)",
    "Isole Cook",
    "Isole Falkland",
    "Isole Faroe",
    "Isole Heard e McDonald",
    "Isole Marshall",
    "Isole Salomone",
    "Isole Vergini Americane",
    "Isole Vergini Britanniche",
    "Israele",
    "Italia",
    "Jersey",
    "Kazakistan",
    "Kenya",
    "Kirghizistan",
    "Kiribati",
    "Kuwait",
    "Laos",
    "Lesotho",
    "Lettonia",
    "Libano",
    "Liberia",
    "Libia",
    "Liechtenstein",
    "Lituania",
    "Lussemburgo",
    "Macao",
    "Macedonia del Nord",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldive",
    "Mali",
    "Malta",
    "Marocco",
    "Martinica",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Messico",
    "Micronesia",
    "Moldavia",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Mozambico",
    "Namibia",
    "Nauru",
    "Nepal",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Northern Mariana Islands",
    "Norvegia",
    "Nuova Caledonia",
    "Nuova Zelanda",
    "Oman",
    "Paesi Bassi",
    "Pakistan",
    "Panama",
    "Papua Nuova Guinea",
    "Paraguay",
    "Perù",
    "Pitcairn",
    "Polinesia Francese",
    "Polonia",
    "Portogallo",
    "Puerto Rico",
    "Qatar",
    "Regno Unito (UK)",
    "Repubblica Ceca",
    "Repubblica Centrafricana",
    "Repubblica Dominicana",
    "Reunion",
    "Romania",
    "Russia",
    "Rwanda",
    "Sahara Occidentale",
    "Saint Helena",
    "Saint Kitts e Nevis",
    "Saint Lucia",
    "Saint Martin (Francia)",
    "Saint Martin (Paesi Bassi)",
    "Saint Vincent e Grenadine",
    "Saint-Barthélemy",
    "Saint-Pierre e Miquelon",
    "Samoa",
    "San Marino",
    "São Tomé e Príncipe",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Siria",
    "Slovacchia",
    "Slovenia",
    "Somalia",
    "South Georgia/Sandwich Islands",
    "Spagna",
    "Sri Lanka",
    "Stati Uniti (US)",
    "Stati Uniti (US) Isole Minori",
    "Sud Africa",
    "Sudan",
    "Sudan del Sud",
    "Suriname",
    "Svalbard e Jan Mayen",
    "Svezia",
    "Svizzera",
    "Tagikistan",
    "Tailandia",
    "Taiwan",
    "Tanzania",
    "Terre Australi e Antartiche Francesi",
    "Territori palestinesi",
    "Territorio Britannico dell'Oceano Indiano",
    "Timor Est",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad e Tobago",
    "Tunisia",
    "Turchia",
    "Turkmenistan",
    "Turks e Caicos",
    "Tuvalu",
    "Ucraina",
    "Uganda",
    "Ungheria",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vaticano",
    "Venezuela",
    "Vietnam",
    "Wallis e Futuna",
    "Yemen",
    "Zambia",
    "Zimbabwe"
];

watch(() => selNazione.value, async () => {
    try {
        const response = await axios.post(`api/nazione/${cliente.value.id}?_method=PUT`, {
            nazionalita: selNazione.value
        });

        if (response.data.id != cliente.value.id) {
            $wal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Si è verificato un errore, riprova più tardi'
            });
        }
    } catch (error) {
        console.error(error);
        $wal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Si è verificato un errore, riprova più tardi'
        });
    }
})

onMounted(() => {
    populateOptionsFromData(dataFromArray);
    fetchCliente();
    fetchLabels();
});
</script>

<style>
#elenco-concorsi-cliente {
    background-color: #fff;
}

#elenco-concorsi-cliente:nth-child(even) {
    background-color: #f2f2f2;
}

.popup-qrCode {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-qrCode-content {
    background: white;
    padding: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.popup-concorsi {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-concorsi-content {
    background: white;
    padding: 20px;

    width: 70%;
    display: flex;
    flex-direction: column;
    position: relative;
}

#popup-concorsi-lista {
    max-height: 400px;
    overflow-y: scroll;
}

#qrCodeElement {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button {
    display: inline-block;
    padding: 10px 20px;
    margin: 0 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}

.primary-button {
    background: white;
    color: gray;
    border-color: rgb(34, 197, 94);
    font-weight: bold;
}

.primary-button:hover {
    background: rgb(34, 197, 94);
    color: white;
}

.secondary-button {
    background: white;
    color: black;
    border: 1px solid orange;
}

qrcode-vue {
    margin-bottom: 20px;
}

.buttons {
    width: 300px;
    display: flex;
    justify-content: center;
}

.close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
    background: none;
    font-size: 25px;
    cursor: pointer;
}

.close-button i {
    color: #333;
}

.label {
    page-break-after: always;
}
</style>