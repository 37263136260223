<template>
    <div>
        <button @click="print">Stampa etichette</button>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import QRCode from 'qrcode-svg'

const labels = ref([])
const id = ref(418)

async function print() {
  const iframe = document.createElement('iframe')

  iframe.onload = async () => {
    const printSection = document.createElement('div')

    const labelsHtml = await generateLabelsHtml(labels.value)

    printSection.innerHTML = labelsHtml

    addPrintStylesheet(printSection)

    iframe.contentDocument.body.appendChild(printSection)

    iframe.contentWindow.focus()
    iframe.contentWindow.print()
  }

  iframe.style.display = 'none'
  document.body.appendChild(iframe)
}

async function generateLabelsHtml(labels) {
  const htmlPromises = labels.map(async (label) => {
    const qrCode = new QRCode({
      content: `https://3wa.it/vota-pizza/${label.pivot.id}`,
      padding: 0,
      width: 110,
      height: 110,
      color: '#000000',
      background: '#ffffff',
    })

    const qrCodeSvg = qrCode.svg()

    return `
      <div class="label">
        <div>
          <h3 class="titolo">Pizza Doc</h3>
          <h6 class="sottotitolo">World Championship</h6>
        </div>

        <div class="qr-code">
            ${qrCodeSvg}
        </div>

        <p class="concorso">${label.nome_concorso}</p>
        <p class="short-code">${label.shortCode} || ${id.value}</p>
        <p class="nota-pie">Accedi all'app, vai sul menu "vota" e leggi il QrCode per poter esprimere il tuo voto</p>
      </div>
    `
  })

  const htmls = await Promise.all(htmlPromises)

  return htmls.join('')
}

function addPrintStylesheet(element) {

    const printStyle = document.createElement('style')

    printStyle.innerHTML = `
    @media print {
        @page {
            size: 40mm 89mm;
            margin: 5mm; 
            margin-left: 3mm;
            margin-right: 3mm;
        }
        .label {
            page-break-after: always;
        }
        .titolo {
            text-align: center;
            margin-bottom: 1px;
        }
        .sottotitolo {
            text-align: center;
            margin-top: 0px;
        }
        .qr-code {
            text-align: center;
            margin: 5px 0 5px 0;
        }
        .concorso {
            font-size: 8px;
            margin-top: 5px;
        }
        .short-code {
            font-size: 7px;
            margin-top: 7px;
        }
        .nota-pie {
            position: absolute;
            font-size: 6px;
            bottom: 3px;
        }
    }
  `

    element.appendChild(printStyle)

}

async function fetchLabels() {
    try {
        const response = await axios.get(`/api/clienti/${id.value}/concorsi`);
        labels.value = response.data.concorsi;
    } catch (error) {
        console.error(error);
    }
}

onMounted(() => {
    fetchLabels()
})

</script>

<style>
.label {
    page-break-after: always;
}
</style>