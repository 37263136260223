<template>
    <div id="" class="container mx-auto pt-5 pb-20 px-6">
        <div class="flex justify-between items-center pt-1">
            <div class="text-gray-900 text-xl">
                Concorrenti
            </div>

            <input v-model="search" class="border border-gray-300 rounded-md p-2 mr-2" type="search" placeholder="Cerca...">

            <RouterLinkButton btnText="Nuovo Concorrente" color="green" url="/clienti/create-cliente" />
        </div>

        <div class="bg-green-500 w-full h-1 my-4"></div>
        <div v-if="clients">
            <div class="scroll-container">
                <div class="table-wrapper">
                    <table>
                        <thead>
                            <tr>
                                <th style="width: 40px;"></th>
                                <th style="width: 60px;">QrCode</th>
                                <th style="width: 280px;">Nome e Cognome</th>
                                <th style="width: 150px;">Nazionalità</th>
                                <th style="width: 150px;">Telefono</th>
                                <th style="width: 280px;">Email</th>
                                <th style="width: 40px;">Taglia</th>
                                <th style="width: 280px;">Luogo di lavoro</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(client, index) in clients" :key="client.id" :class="{ 'bg-even': index % 2 === 0 }">
                                <td style="width: 40px;">
                                    <router-link :to="{ name: 'DettaglioCliente', params: { id: client.id } }">
                                        <i class="fas fa-search fa-1x mr-4"></i>
                                    </router-link>
                                </td>
                                <td style="width: 60px;">
                                    <div :class="client.accreditato ? 'bg-green-500' : 'bg-red-500'"
                                        class="text-white px-2 w-full h-full flex items-center justify-center">{{
                                            client.shortCode }}</div>
                                </td>
                                <td style="width: 280px;">{{ client.cognome }} {{ client.nome }}</td>
                                <td style="width: 150px;">{{ client.nazionalita }}</td>
                                <td style="width: 150px;">{{ client.telefono }}</td>
                                <td style="width: 280px;">{{ client.email }}</td>
                                <td style="width: 40px;">{{ client.taglia }}</td>
                                <td style="width: 280px;">{{ client.luogo_lavoro }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="bg-green-500 w-full h-1 my-4"></div>
            <div v-if="totalPages > 1">
                <div class="pagination">
                    <button class="mr-4" v-for="page in totalPages" :key="page" :disabled="page === currentPage"
                        @click="getClients(page)">
                        {{ page }}
                    </button>
                </div>
            </div>
        </div>
        <div class="w-full pl-4" v-else>
            Caricamento in corso...
        </div>
    </div>
</template>

<style>
.scroll-container {
    overflow-x: auto;
    width: 100%;
}

.table-wrapper {
    width: max-content;
    margin: 0 auto;
}

table {
    border-collapse: collapse;
    width: 100%;
}

th,
td {
    padding: 8px;
    text-align: left;
}

.bg-even {
    background-color: #f2f2f2;
}

.bg-green-500 {
    background-color: green;
}

.bg-red-500 {
    background-color: red;
}

.rounded-full {
    border-radius: 50%;
}
</style>

<script setup>
import axios from 'axios'
import { onMounted, ref, watch } from 'vue'
import { debounce } from 'lodash'
import RouterLinkButton from '../../components/global/RouterLinkButton.vue'

const clients = ref([])
let totalPages = ref(0)
let search = ref('')
let currentPage = ref(1)

async function searchClients() {

    const response = await axios.get(`/api/clienti/search?filtro=${search.value}`);
    clients.value = response.data.paginate.data
    totalPages.value = response.data.page_count
}

watch(search, debounce((value) => {
    if (value.length > 3) {
        searchClients()
    } else {
        getClients(1)
    }
}, 300))

function getClients(page) {
    currentPage.value = page
    axios.get('/api/clienti', {
        params: {
            page
        }
    })
        .then(res => {
            clients.value = res.data.paginate.data
            totalPages.value = res.data.page_count
        })
}


const handleScroll = () => {
    const visibleColumns = document.querySelectorAll(".row:in-viewport");

    visibleColumns.forEach((column, index) => {
        if (index % 2 === 0) {
            column.classList.add("bg-even");
        } else {
            column.classList.remove("bg-even");
        }
    });
};

onMounted(() => {
    getClients(1);
    const container = document.querySelector(".scroll-container");
    container.addEventListener("scroll", handleScroll);
});
</script>

<style>
button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination {
    display: flex;
}

.pagination button {
    border: 1px solid #ccc;
    padding: 8px 12px;
    margin: 0 4px;
    border-radius: 4px;
}

.pagination button:first-child {
    margin-left: 0;
}

.pagination button:last-child {
    margin-right: 0;
}
</style>