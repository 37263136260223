<template>
    <div id="Register" class="register-view">
        <div class="w-full p-6 flex justify-center items-center">
            <div class="w-full max-w-xs">

                <div class="bg-black p-8 shadow rounded mb-6 wrapper-box-register">
                    <h1 class="mb-6 text-lg text-gray-100 font-thin">Registrati qui!</h1>

                    <div class="mb-4">
                        <TextInput label="Nome" :labelColor="false" placeholder="Nome" v-model:input="firstName"
                            inputType="text" :error="errors.first_name ? errors.first_name[0] : ''" />
                    </div>

                    <div class="mb-4">
                        <TextInput label="Cognome" :labelColor="false" placeholder="Cognome" v-model:input="lastName"
                            inputType="text" :error="errors.last_name ? errors.last_name[0] : ''" />
                    </div>

                    <div class="mb-4">
                        <TextInput label="Email" :labelColor="false" placeholder="Email" v-model:input="email"
                            inputType="text" :error="errors.email ? errors.email[0] : ''" />
                    </div>
                    <div class="mb-4">
                        <TextInput label="Password" :labelColor="false" placeholder="Password" v-model:input="password"
                            inputType="password" :error="errors.password ? errors.password[0] : ''" />
                    </div>
                    <div class="mb-4">
                        <TextInput label="Conferma Password" :labelColor="false" placeholder="Conferma password"
                            v-model:input="confirmPassword" inputType="password" />
                    </div>
                    <button class="
                        block
                        w-full
                        bg-green-500
                        text-white
                        text-center
                        rounded-sm
                        py-3
                        text-sm
                        tracking-wide
                    " type="submit" @click="register">
                        Registrati
                    </button>
                </div>

                <p v-if="1 === 2" class="text-center text-md text-gray-900">
                    Hai già un account?
                    <router-link to="login" class="text-blue-500 no-underline hover:underline">
                        Entra
                    </router-link>
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
import axios from 'axios';
import { ref } from 'vue';
import TextInput from '../components/global/TextInput.vue';
import $wal from '../sweetalert2'
import { useRouter } from 'vue-router'

const router = useRouter()

let errors = ref([])
let firstName = ref(null)
let lastName = ref(null)
let email = ref(null)
let password = ref(null)
let confirmPassword = ref(null)

const register = async () => {

    errors.value = []

    try {
        await axios.post('api/register', {
            first_name: firstName.value,
            last_name: lastName.value,
            email: email.value,
            password: password.value,
            password_confirmation: confirmPassword.value,
        })

        $wal.fire(
            'Account creato!',
            'Hai aggiunto l\'utente "' + firstName.value + ' ' + lastName.value + '"',
            'success'
        )

        router.push('/giudici/show')

    } catch (err) {
        errors.value = err.response.data.errors
    }
}
</script>