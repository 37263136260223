<template>
    <div id="" class="container max-w-4xl mx-auto pt-5 pb-20 px-6">
        <div class="flex justify-between items-center pt-1">
            <div class="text-gray-900 text-xl">
                Teams
            </div>

            <RouterLinkButton btnText="Nuovo Team" color="green" url="/teams/create-team" />
        </div>

        <div class="bg-green-500 w-full h-1 my-4"></div>
        <div v-if="teams">
            <div class="flex py-2 font-bold items-center">
                <div class="w-1/6">
                    Dettaglio
                </div>
                <div class="w-1/6">
                    Id
                </div>
                <div class="w-4/6">
                    Nome Team
                </div>
            </div>

            <div v-for="team in teams" :key="team.id" id="elenco-teams" class="flex py-2 items-center">
                <div class="w-1/6">
                    <router-link :to="{ name: 'DettaglioTeam', params: { id: team.id } }">
                        <i class="fas fa-search fa-1x mr-4"></i>
                    </router-link>
                </div>
                <div class="w-1/6">
                    {{ team.id }}
                </div>

                <div class="w-4/6">
                    {{ team.nome_concorso }}
                </div>
            </div>
        </div>
        <div class="w-full pl-4" v-else>
            Caricamento in corso...
        </div>
    </div>
</template>

<style>
#elenco-teams {
    background-color: #fff;
}

#elenco-teams:nth-child(even) {
    background-color: #f2f2f2;
}
</style>

<script setup>
import axios from 'axios'
import { onMounted, ref } from 'vue'
import RouterLinkButton from '../../components/global/RouterLinkButton.vue'

const teams = ref([])

async function fetchTeams() {
    try {
        const response = await axios.get('/api/teams');
        teams.value = response.data;
    } catch (error) {
        console.log(error);
    }
}

onMounted(() => {
    fetchTeams()
})
</script>