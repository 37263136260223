<template>
    <div class="container max-w-4xl mx-auto flex mt-10">
        <div class="w-full pl-4 mb-6" v-if="team">
            <div class="flex">
                <div class="w-2/3">
                    <h1 class="text-2xl md:text-4xl text-left text-gray-900">
                        {{ team.nome_concorso }}
                    </h1>
                    <div class="bg-green-500 w-full h-1 my-4"></div>
                    <div class="text-md text-gray-700">
                        Pizza in pala: <i><b>{{ team.cliente_pala.cognome }} {{ team.cliente_pala.nome }} [{{ team.cliente_pala.shortCode }}]</b></i>
                    </div>
                    <div class="text-md text-gray-700">
                        Pizza in teglia: <i><b>{{ team.cliente_teglia.cognome }} {{ team.cliente_teglia.nome }} [{{ team.cliente_teglia.shortCode }}]</b></i>
                    </div>
                    <div class="text-md text-gray-700">
                        Pizza senza glutine: <i><b>{{ team.cliente_glutine.cognome }} {{ team.cliente_glutine.nome }} [{{ team.cliente_glutine.shortCode }}]</b></i>
                    </div>
                    <div class="text-md text-gray-700">
                        Pinsa romana: <i><b>{{ team.cliente_romana.cognome }} {{ team.cliente_romana.nome }} [{{ team.cliente_romana.shortCode }}]</b></i>
                    </div>
                    <div class="text-md text-gray-700">
                        Pizza fritta: <i><b>{{ team.cliente_fritta.cognome }} {{ team.cliente_fritta.nome }} [{{ team.cliente_fritta.shortCode }}]</b></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full pl-4" v-else>
            Caricamento in corso...
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';

const route = useRoute();
const id = ref(route.params.id);
const team = ref(null);


const fetchTeam = async () => {
    try {
        const response = await axios.get(`/api/teams/${id.value}`);
        team.value = response.data;
    } catch (error) {
        console.error(error);
    }
};

onMounted(() => {
    fetchTeam();
});
</script>

<style>
.button {
    display: inline-block;
    padding: 10px 20px;
    margin: 0 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}

.primary-button {
    background: white;
    color: gray;
    border-color: rgb(34, 197, 94);
    font-weight: bold;
}

.primary-button:hover {
    background: rgb(34, 197, 94);
    color: white;
}

.secondary-button {
    background: white;
    color: black;
    border: 1px solid orange;
}

.buttons {
    width: 300px;
    display: flex;
    justify-content: center;
}
</style>

