<template>
    <div v-if="ruolo">
        <button @click="open = !open" class="
                bg-transparent
                
                text-white
                font-semibold
                hover:text-white
                py-1
                px-2
                hover:border-transparent
                rounded ">

            <i class="fa-solid fa-bars fa-xl"></i>
        </button>

        <div v-show="open" class="
                animated 
                slideInDown 
                faster 
                fixed
                w-full
                h-full
                top-0
                left-0
                flex
                justify-center
                backdrop-blur-sm">
            <div class="bg-black absolute w-full h-full opacity-75"></div>
            <div id="bottoni-menu" class="my-auto fixed border-white w-80 pt-16">
                <p class="text-2xl text-center text-white font-bold">Benvenuto: {{ userStore.firstName }} {{
                    userStore.lastName }}</p>
                <div v-if="ruolo === 'A' || ruolo === 'U'">
                    <RouterLinkButton @click="open = !open" class="w-full 
                            text-white 
                            text-center 
                            text-lg
                            mt-2" btnText="Dashboard" color="green" url="/dashboard" />

                    <RouterLinkButton @click="open = !open" class="w-full 
                            text-white 
                            text-center 
                            text-lg
                            mt-2" btnText="Concorrenti" color="green" url="/clienti/show" />

                    <RouterLinkButton @click="open = !open" class="w-full 
                            text-white 
                            text-center 
                            text-lg
                            mt-2" btnText="Teams" color="green" url="/teams/show" />
                </div>
                <div v-if="ruolo === 'G'">
                    <RouterLinkButton @click="open = !open" class="w-full 
                            text-white 
                            text-center 
                            text-lg
                            mt-2" btnText="Dashboard" color="green" url="/dashgiudice" />
                    <RouterLinkButton @click="open = !open" class="w-full 
                            text-white 
                            text-center 
                            text-lg
                            mt-2" btnText="Vota" color="green" url="/testQR" />
                </div>
                <div v-if="ruolo === 'H'">
                    <RouterLinkButton @click="open = !open" class="w-full 
                            text-white 
                            text-center 
                            text-lg
                            mt-2" btnText="Dashboard" color="green" url="/dashassistenza" />
                    <RouterLinkButton @click="open = !open" class="w-full 
                            text-white 
                            text-center 
                            text-lg
                            mt-2" btnText="Leggi QrCode" color="green" url="/manualQR" />
                </div>
                <div v-if="ruolo === 'A'">
                    <RouterLinkButton @click="open = !open" class="w-full 
                            text-white 
                            text-center 
                            text-lg
                            mt-2" btnText="Giudici" color="green" url="/giudici/show" />

                     <RouterLinkButton @click="open = !open" class="w-full 
                            text-white 
                            text-center 
                            text-lg
                            mt-2" btnText="Download" color="green" url="/download-csv" /> 
                </div>
                <div>
                    <RouterLinkButton @click="logout" class="w-full 
                            text-white 
                            text-center 
                            text-lg
                            mt-2" btnText="Logout" color="green" url="" />
                </div>
            </div>
            <div class="absolute top-10 right-10" @click="open = false">
                <i class="fa fa-times text-2xl text-white cursor-pointer"></i>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import RouterLinkButton from '../global/RouterLinkButton.vue'
import { useUserStore } from '../../store/user-store';
import axios from 'axios';
import { useRouter } from 'vue-router'

const router = useRouter()

let errors = ref([])

let open = ref(false)
let ruolo = ref('')
const userStore = useUserStore();

const logout = async () => {
    errors.value = []
    try {
        await axios.post('api/logout', {
            user_id: userStore.id
        });
        userStore.clearUser();
        refreshPage();

    } catch (err) {
        console.log("Errore", err);
        errors.value = err.response.data.errors
    }
}

function refreshPage() {
    router.go(router.currentRoute.value.path)
}

onMounted(() => {
    ruolo.value = userStore && !isNaN(userStore.id) ? userStore.role : '';
});

</script>

<style> 
 .fa-times {
     font-size: 1.5rem;
 }
#bottoni-menu a.text-gray-500 {
    color: white !important;
}

</style> 