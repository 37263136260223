<template>
    <div class="bg-gary-50 px-8">
        <p>{{ error }}</p>
        <p>{{ decodedString }}</p>
        <button class="text-green-800" @click="torch = !torch">ON/OFF FLASH</button>
        <div class="w-screen/2">
            <qrcode-stream @init="onInit" @decode="onDecode" :torch="torch"></qrcode-stream>
        </div>
    </div>
</template>
<script>
import { QrcodeStream } from 'vue3-qrcode-reader'
export default {
    data() {
        return {
            error: '',
            decodedString: '',
            torch: false,
        }
    },
    components: {
        QrcodeStream
    },
    methods: {
        async onInit(promise) {
            try {
                const { capabilities } = await promise
                console.log(capabilities);
            } catch (error) {
                if (error.name == 'NotAllowedError') {
                    this.error = "L'utente ha negato l'accesso alla fotocamera"
                } else if (error.name == 'NotFoundError') {
                    this.error = "Non è presente la fotocamera"
                } else if (error.name == 'NotSupportedError') {
                    this.error = "La pagina non ha un protocollo HTTPS"
                } else if (error.name == 'NotReadableError') {
                    this.error = "La fotocamera potrebbe già essere in uso"
                } else if (error.name == 'OverconstrainedError') {
                    this.error = "Hai richiesto di utilizzare la fotocamera anteriore che non è presente?"
                } else if (error.name == 'StreamApiNotSupportedError') {
                    this.error = "Sembra che il browser utilizzato non sia supportato"
                }
            }
        },
        onDecode(decodedString) {
            //window.location.replace(decodedString);
            try {
                const match = decodedString.match(/\/(\d+)$/);

                if (match) {
                    const numeroFinale = parseInt(match[1]);
                    console.log('vado alla pagina del voto');
                    //                    window.location.replace(`https://www.3wa.it/ottieniShortCode/${numeroFinale}`);
                    this.$router.push({ name: 'VotaCliente', params: { id: numeroFinale } });
                }
            }
            catch (error) {
                console.log("Lettura qrCode: ", error);
            }
        }
    }
}
</script>