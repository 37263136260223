<template>
    <div id="" class="container max-w-4xl mx-auto pt-20 pb-20 px-6">
        <div class="text-gray-900 text-xl">Nuovo Concorrente</div>
        <div class="bg-green-500 w-full h-1"></div>

        <div class="flex flex-wrap mt-4 mb-6">
            <div class="w-full md:w1/2 px-3 mb-3">
                <TextInput label="Nome" placeholder="John" v-model:input="nome" inputType="text"
                    :error="errors.nome ? errors.nome[0] : ''" />
            </div>
            <div class="w-full md:w1/2 px-3 mb-3">
                <TextInput label="Cognome" placeholder="Doe" v-model:input="cognome" inputType="text"
                    :error="errors.cognome ? errors.cognome[0] : ''" />
            </div>
            <div class="w-full md:w1/2 px-3 mb-3">
                <TextInput label="Email" placeholder="jd@a.com" v-model:input="email" inputType="email"
                    :error="errors.email ? errors.email[0] : ''" />
            </div>
            <div class="w-full md:w1/2 px-3 mb-3">
                <label class=" block uppercase tracking-wide text-xs font-bold mb-2 text-gray-900" >
                    Nazionalità
                </label>
                <vue-select v-model="selNazione" :options="optionsNazione" :reduce="option => option.value" placeholder=""
                    @search="handleSearch"></vue-select>
                <span v-if="errors.nazionalita" class="text-red-500">
                    {{ errors.nazionalita[0] }}
                </span>
            </div>
            <div class="w-full md:w1/2 px-3 mb-3">
                <TextInput label="Telefono" placeholder="00393333333333" v-model:input="telefono" inputType="text"
                    :error="errors.telefono ? errors.telefono[0] : ''" />
            </div>
            <div class="w-full md:w1/2 px-3 mb-3">
                <label class="block 
                                uppercase 
                                tracking-wide 
                                text-xs         
                                font-bold 
                                mb-2        
                                text-gray-100
                                text-gray-900
                            ">
                    Taglia
                </label>
                <select
                    class="w-1/3 px-3 py-2 rounded-md bg-gray-50 border border-gray-300 text-sm shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                    v-model="taglia">
                    <option value="S">S</option>
                    <option value="M">M</option>
                    <option value="L">L</option>
                    <option value="XL">XL</option>
                    <option value="XXL">XXL</option>
                    <option value="3XL">3XL</option>
                    <option value="4XL">4XL</option>
                </select>
            </div>
            <div class="w-full md:w1/2 px-3 mb-3">
                <TextInput label="Luogo di lavoro" placeholder="Pizzeria lunarossa" v-model:input="luogo" inputType="text"
                    :error="errors.luogo ? errors.luogo[0] : ''" />
            </div>
            <div class="w-full md:w1/2 px-3 mb-3">
                <TextInput label="Short Code" placeholder="AD1234" v-model:input="short_code" inputType="text"
                    :error="errors.shortCode ? errors.shortCode[0] : ''" v-show="false" />
            </div>
        </div>

        <div class="flex flex-wrap mt-8 mb-6">
            <div class="w-full px-3">
                <SubmitFormButton btnText="Conferma" @submit="createCliente" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import TextInput from '../../components/global/TextInput.vue'
import SubmitFormButton from '../../components/global/SubmitFormButton.vue'
import axios from 'axios';
import { useRouter } from 'vue-router';
import $wal from '../../sweetalert2';

import VueSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

const router = useRouter()

let nome = ref(null)
let cognome = ref(null)
let short_code = ref(null)
//let nazionalita = ref(null)
let telefono = ref(null)
let taglia = ref(null)
let email = ref(null)
let luogo = ref(null)
let errors = ref([])

const optionsNazione = ref([]);
const selNazione = ref(null)

const createCliente = async () => {
    errors.value = []

    let data = new FormData();

    data.append('nome', nome.value || '')
    data.append('cognome', cognome.value || '')
    data.append('email', email.value || '')
    data.append('nazionalita', selNazione.value || '')
    data.append('telefono', telefono.value || '')
    data.append('taglia', taglia.value || '')
    data.append('luogo', luogo.value || '')
    data.append('shortCode', short_code.value || '')

    try {
        await axios.post('api/crea-cliente', data)
        $wal.fire(
            'Nuovo cliente creato!',
            '',
            'success'
        )

        router.push('/clienti/show')

    } catch (err) {
        errors.value = err.response.data.errors;
    }
}

function populateOptionsFromData(data) {
    optionsNazione.value = data.map((item) => ({
        value: item,
        label: item
    }));
}

const dataFromArray = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antartide",
    "Antigua e Barbuda",
    "Arabia Saudita",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belau",
    "Belgio",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bielorussia",
    "Birmania",
    "Bolivia",
    "Bonaire, Saint Eustatius e Saba",
    "Bosnia-Erzegovina",
    "Botswana",
    "Brasile",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambogia",
    "Camerun",
    "Canada",
    "Capo Verde",
    "Ciad",
    "Cile",
    "Cina",
    "Cipro",
    "Colombia",
    "Comore",
    "Congo (Brazzaville)",
    "Congo (Kinshasa)",
    "Corea del Nord",
    "Corea del Sud",
    "Costa d'Avorio",
    "Costa Rica",
    "Croazia",
    "Cuba",
    "Curaçao",
    "Danimarca",
    "Dominica",
    "Ecuador",
    "Egitto",
    "El Salvador",
    "Emirati Arabi Uniti",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Etiopia",
    "Figi",
    "Filippine",
    "Finlandia",
    "Francia",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germania",
    "Ghana",
    "Giamaica",
    "Giappone",
    "Gibilterra",
    "Gibuti",
    "Giordania",
    "Grecia",
    "Grenada",
    "Groenlandia",
    "Guadalupa",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea Equatoriale",
    "Guinea-Bissau",
    "Guyana",
    "Guyana Francese",
    "Haiti",
    "Honduras",
    "Hong Kong",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Irlanda",
    "Islanda",
    "Isola Bouvet",
    "Isola di Man",
    "Isola di Natale",
    "Isola Norfolk",
    "Isole Åland",
    "Isole Cayman",
    "Isole Cocos (Keeling)",
    "Isole Cook",
    "Isole Falkland",
    "Isole Faroe",
    "Isole Heard e McDonald",
    "Isole Marshall",
    "Isole Salomone",
    "Isole Vergini Americane",
    "Isole Vergini Britanniche",
    "Israele",
    "Italia",
    "Jersey",
    "Kazakistan",
    "Kenya",
    "Kirghizistan",
    "Kiribati",
    "Kuwait",
    "Laos",
    "Lesotho",
    "Lettonia",
    "Libano",
    "Liberia",
    "Libia",
    "Liechtenstein",
    "Lituania",
    "Lussemburgo",
    "Macao",
    "Macedonia del Nord",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldive",
    "Mali",
    "Malta",
    "Marocco",
    "Martinica",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Messico",
    "Micronesia",
    "Moldavia",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Mozambico",
    "Namibia",
    "Nauru",
    "Nepal",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Northern Mariana Islands",
    "Norvegia",
    "Nuova Caledonia",
    "Nuova Zelanda",
    "Oman",
    "Paesi Bassi",
    "Pakistan",
    "Panama",
    "Papua Nuova Guinea",
    "Paraguay",
    "Perù",
    "Pitcairn",
    "Polinesia Francese",
    "Polonia",
    "Portogallo",
    "Puerto Rico",
    "Qatar",
    "Regno Unito (UK)",
    "Repubblica Ceca",
    "Repubblica Centrafricana",
    "Repubblica Dominicana",
    "Reunion",
    "Romania",
    "Russia",
    "Rwanda",
    "Sahara Occidentale",
    "Saint Helena",
    "Saint Kitts e Nevis",
    "Saint Lucia",
    "Saint Martin (Francia)",
    "Saint Martin (Paesi Bassi)",
    "Saint Vincent e Grenadine",
    "Saint-Barthélemy",
    "Saint-Pierre e Miquelon",
    "Samoa",
    "San Marino",
    "São Tomé e Príncipe",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Siria",
    "Slovacchia",
    "Slovenia",
    "Somalia",
    "South Georgia/Sandwich Islands",
    "Spagna",
    "Sri Lanka",
    "Stati Uniti (US)",
    "Stati Uniti (US) Isole Minori",
    "Sud Africa",
    "Sudan",
    "Sudan del Sud",
    "Suriname",
    "Svalbard e Jan Mayen",
    "Svezia",
    "Svizzera",
    "Tagikistan",
    "Tailandia",
    "Taiwan",
    "Tanzania",
    "Terre Australi e Antartiche Francesi",
    "Territori palestinesi",
    "Territorio Britannico dell'Oceano Indiano",
    "Timor Est",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad e Tobago",
    "Tunisia",
    "Turchia",
    "Turkmenistan",
    "Turks e Caicos",
    "Tuvalu",
    "Ucraina",
    "Uganda",
    "Ungheria",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vaticano",
    "Venezuela",
    "Vietnam",
    "Wallis e Futuna",
    "Yemen",
    "Zambia",
    "Zimbabwe"
];

onMounted(() => {
    populateOptionsFromData(dataFromArray);
});

</script>