<template>
  <TopNavigation />
  <div>
    <div id="" class="container max-w-4xl mx-auto pt-8 pb-20 px-6 mb-20">
      <table id="tab-filtri">
        <thead>
        </thead>
        <tbody>
          <tr>
            <td style="width: 20px;">Gara:</td>
            <td style="width: 250px;">
              <select
                class="flex-1 px-3 py-2 rounded-md bg-gray-50 border border-gray-300 text-sm shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed align-middle mb-3"
                v-model="filtro_gara">
                <option value=""></option>
                <option value="1">Pizza Classica</option>
                <option value="2">La Pizza "ruota di carro" o "rota 'e carretta"</option>
                <option value="3">Pizza Margherita DOC</option>
                <option value="4">Pizza Contemporanea</option>
                <option value="5">Pizza in Teglia</option>
                <option value="6">Pizza in Pala</option>
                <option value="7">Pinsa Romana</option>
                <option value="8">Pizza senza Glutine</option>
                <option value="9">Pizza Fritta</option>
                <option value="10">Fritti</option>
                <option value="11">Pizza Gourmet</option>
                <option value="12">Pizza del Cilento</option>
                <option value="13">Pizza più Larga</option>
                <option value="14">Free style</option>
                <option value="15">Pizza più veloce</option>
                <option value="16">Speedy box PizzaDoc</option>
                <option value="17">Pizza in Team</option>
                <option value="18">Pizza a Due</option>
                <option value="19">PizzaDocLovers</option>
                <option value="20">Pizza social</option>
              </select>
            </td>
          </tr>
          <tr>
            <td style="width: 20px;">Data:</td>
            <td style="width: 250px;">
              <select
                class="flex-1 px-3 py-2 rounded-md bg-gray-50 border border-gray-300 text-sm shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed align-middle mb-3"
                v-model="filtro_data">
                <option value=""></option>
                <option value="lunedi-13-novembre">lunedi-13-novembre</option>
                <option value="martedi-14-novembre">martedi-14-novembre</option>
                <option value="mercoledi-15-novembre">mercoledi-15-novembre</option>
              </select>
            </td>
          </tr>
          <tr>
            <td style="width: 20px;">Ora:</td>
            <td style="width: 250px;">
              <select
                class="flex-1 px-3 py-2 rounded-md bg-gray-50 border border-gray-300 text-sm shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed align-middle mb-3"
                v-model="filtro_orario">
                <option value=""></option>
                <option value="10:00-11:00">10:00-11:00</option>
                <option value="11:00-12:00">11:00-12:00</option>
                <option value="12:00-13:00">12:00-13:00</option>
                <option value="13:00-14:00">13:00-14:00</option>
                <option value="14:00-15:00">14:00-15:00</option>
                <option value="15:00-16:00">15:00-16:00</option>
                <option value="16:00-17:00">16:00-17:00</option>
                <option value="17:00-18:00">17:00-18:00</option>
              </select>
            </td>
          </tr>
          <tr>
            <td style="width: 20px;">Forno:</td>
            <td style="width: 250px;">
              <select
                class="flex-1 px-3 py-2 rounded-md bg-gray-50 border border-gray-300 text-sm shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed align-middle mb-3"
                v-model="filtro_forno">
                <option value=""></option>
                <option value="legna">legna</option>
                <option value="elettrico">elettrico</option>
              </select>
            </td>
          </tr>
          <tr>
            <td style="width: 20px;">Filtro:</td>
            <td style="width: 250px;">
              <input v-model="filtro_testo" class="border border-gray-300 rounded-md p-2 mr-2" type="search"
                placeholder="Cerca...">
            </td>
          </tr>
        </tbody>
      </table>

      <div class="text-gray-900 text-xl mt-2">Elenco gare [{{ concorsiFiltrati.length }}]</div>
      <div class="bg-green-500 w-full h-1"></div>

      <div class="scroll-container">
        <div class="table-wrapper">
          <table>
            <thead>
              <tr>
                <th style="width: 40px;"></th>
                <th style="width: 200px;">Gara</th>
                <th style="width: 150px;">Data</th>
                <th style="width: 80px;">Ora</th>
                <th style="width: 80px;">ShortCode</th>
                <th style="width: 250px;">Cognome</th>
                <th style="width: 250px;">Nome</th>
                <th style="width: 80px;">Accreditato</th>
                <th style="width: 150px;">Gareggia</th>
                <th style="width: 120px;">N° Forno</th>
                <th style="width: 120px;">Nota</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(concorso, index) in concorsiFiltrati" :key="concorso.id"
                :class="{ 'bg-even': index % 2 === 0 }">
                <td style="width: 40px;" class="text-center">
                  <router-link :to="{ name: 'EditForno', params: { id: concorso.id } }">
                    <i class="fas fa-pencil fa-1x mr-4"></i>
                  </router-link>
                </td>
                <td style="width: 200px;">{{ concorso.concorso.nome_concorso }}</td>
                <td style="width: 150px;">{{ concorso.data }} </td>
                <td style="width: 80px;">{{ concorso.orario }}</td>
                <td style="width: 80px;">{{ concorso.cliente ? concorso.cliente.shortCode : '' }}</td>
                <td style="width: 250x;">{{ concorso.cliente ? concorso.cliente.cognome : '' }}</td>
                <td style="width: 250px;">{{ concorso.cliente ? concorso.cliente.nome : '' }}</td>
                <td style="width: 80px;">{{ concorso.cliente.accreditato ? 'SI' : 'NO' }}</td>
                <td style="width: 80px;">{{ concorso.gareggia ? 'SI' : 'NO' }}</td>
                <td style="width: 120px;">{{ concorso.nr_forno }}</td>
                <td style="width: 120px;">{{ concorso.nota }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <FooterSection />
</template>

<script setup>
import TopNavigation from "../../components/structure/TopNavigation.vue";
import FooterSection from '../../components/structure/FooterSection.vue';
import { onMounted, ref, watch, computed } from 'vue';
import axios from 'axios';
import { useFiltersStore } from '../../store/selections';

const store = useFiltersStore();
let filtro_gara = ref(store.filtro_gara)
let filtro_data = ref(store.filtro_data)
let filtro_orario = ref(store.filtro_orario)
let filtro_forno = ref(store.filtro_forno)
let filtro_testo = ref(store.filtro_testo)

const concorsi = ref(null);

const concorsiFiltrati = computed(() => {
  if (concorsi.value) {
    return concorsi.value.filter(concorso => concorso.voti_count < 3);
  } else {
    // Gestisci il caso in cui concorsi.value è null o asincrono.
    return [];
  }
});

const fetchConcorsi = async () => {
  try {
    let url = '/api/concorsi-forno';

    store.setFiltroData('');
    store.setFiltroOrario('');
    store.setFiltroForno('');
    store.setFiltroGara('');

    if (filtro_data.value) {
      //store.setSelections({ filtro_data: filtro_data.value });
      store.setFiltroData(filtro_data.value);
      url += `?data=${filtro_data.value}`;
    }

    if (filtro_orario.value) {
      store.setFiltroOrario(filtro_orario.value);
      url += filtro_data.value ? '&' : '?';
      url += `orario=${filtro_orario.value}`;
    }

    if (filtro_gara.value) {
      store.setFiltroGara(filtro_gara.value);
      url += (filtro_data.value || filtro_orario.value) ? '&' : '?';
      url += `concorso=${filtro_gara.value}`;
    }

    if (filtro_forno.value) {
      store.setFiltroForno(filtro_forno.value);
      url += (filtro_data.value || filtro_orario.value || filtro_gara.value) ? '&' : '?';
      url += `forno=${filtro_forno.value}`;
    }

    if (filtro_testo.value && (filtro_testo.value.length > 3 || filtro_testo.value.lenght === 0)) {
      store.setFiltroTesto(filtro_testo.value);
      url += (filtro_data.value || filtro_orario.value || filtro_gara.value || filtro_forno.value) ? '&' : '?';
      url += `testo=${filtro_testo.value}`;
    }

    const response = await axios.get(url);
    concorsi.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

watch(() => filtro_data.value, () => {
  fetchConcorsi();
})
watch(() => filtro_orario.value, () => {
  fetchConcorsi();
})
watch(() => filtro_forno.value, () => {
  fetchConcorsi();
})
watch(() => filtro_gara.value, () => {
  fetchConcorsi();
})
watch(() => filtro_testo.value, () => {
    fetchConcorsi();
})

onMounted(() => {
  fetchConcorsi();
});

</script>

<style>
#elenco-concorsi-cliente {
  background-color: #fff;
}

#elenco-concorsi-cliente:nth-child(even) {
  background-color: #f2f2f2;
}

#tab-filtri tr {
  height: 30px;
}

#tab-filtri td {
  padding: 0;
}
</style>