<template>
    <div>
        <div id="" class="container max-w-4xl mx-auto pt-20 pb-20 px-6 mb-20">

            <div class="text-gray-900 text-xl">Comunica il codice al giudice</div>
            <div class="bg-green-500 w-full h-1"></div>
            <div class="text-gray-900 text-4xl w-full text-center mt-4">
                {{ shortCode }}
            </div>

            <div class="text-gray-900 text-2xl w-full text-center mt-4">
                <p>Cliente: {{ cliente }}</p>
                <p>Concorso: {{ concorso }}</p>
                <p>Data: {{  data_concorso }}</p>
            </div>
            <div class="bg-green-500 w-full h-1"></div>
            <div class="text-gray-900 text-2xl w-full text-center mt-4">
                Voti espressi: {{ numVoti }} / 3
            </div>

            <div class="my-auto mt-4">
                <RouterLinkButton class="w-full 
                            text-gray-900 
                            text-center 
                            text-lg
                            mt-2" btnText="Chiudi" color="green" url="/dashassistenza" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router';
import axios from 'axios';
import RouterLinkButton from '../../components/global/RouterLinkButton.vue';

const route = useRoute()

const codice = ref('');
const shortCode = ref('');
const numVoti = ref('');
const cliente = ref('');
const concorso = ref('');
const data_concorso = ref('');

async function getShortCode() {
    try {
        codice.value = route.params.id
        const response = await axios.get(`api/cerca-voto-manuale/${codice.value}`);

        shortCode.value = response.data.shortCode;
        numVoti.value = response.data.numeroVoti;
        cliente.value = response.data.cliente;
        concorso.value = response.data.concorso;
        data_concorso.value = response.data.data_concorso;
        
    } catch (error) {
        console.log(error);
    }
}

onMounted(async () => {
    await getShortCode()
})

</script>