<template>
    <div id="" class="container max-w-4xl mx-auto pt-20 pb-20 px-6">
        <div class="text-gray-900 text-xl">Nuovo Team</div>
        <div class="bg-green-500 w-full h-1"></div>

        <div class="flex flex-wrap mt-4 mb-6">
            <div class="w-full md:w1/2 px-3 mb-3">
                <TextInput label="Nome" placeholder="Dream Team" v-model:input="nome" inputType="text"
                    :error="errors.nome_concorso ? errors.nome_concorso[0] : ''" />
            </div>
            <div class="w-full md:w1/2 px-3 mb-3">
                <div class="text-gray-900 text-xl mb-2">
                    Pizza in pala
                </div>
                <div>
                    <vue-select v-model="selPala" :options="optionsPala" :reduce="option => option.value" placeholder=""
                        @search="handleSearch"></vue-select>
                    <span v-if="errors.pala_id" class="text-red-500">
                        {{ errors.pala_id[0] }}
                    </span>
                </div>
            </div>
            <div class="w-full md:w1/2 px-3 mb-3">
                <div class="text-gray-900 text-xl mb-2">
                    Pizza in teglia
                </div>
                <div>
                    <vue-select v-model="selTeglia" :options="optionsTeglia" :reduce="option => option.value" placeholder=""
                        @search="handleSearch"></vue-select>
                    <span v-if="errors.teglia_id" class="text-red-500">
                        {{ errors.teglia_id[0] }}
                    </span>
                </div>
            </div>
            <div class="w-full md:w1/2 px-3 mb-3">
                <div class="text-gray-900 text-xl mb-2">
                    Pizza senza glutine
                </div>
                <div>
                    <vue-select v-model="selGlutine" :options="optionsGlutine" :reduce="option => option.value"
                        placeholder="" @search="handleSearch"></vue-select>
                    <span v-if="errors.glutine_id" class="text-red-500">
                        {{ errors.glutine_id[0] }}
                    </span>
                </div>
            </div>
            <div class="w-full md:w1/2 px-3 mb-3">
                <div class="text-gray-900 text-xl mb-2">
                    Pinsa romana
                </div>
                <div>
                    <vue-select v-model="selRomana" :options="optionsRomana" :reduce="option => option.value" placeholder=""
                        @search="handleSearch"></vue-select>
                    <span v-if="errors.romana_id" class="text-red-500">
                        {{ errors.romana_id[0] }}
                    </span>
                </div>
            </div>
            <div class="w-full md:w1/2 px-3 mb-3">
                <div class="text-gray-900 text-xl mb-2">
                    Pizza fritta
                </div>
                <div>
                    <vue-select v-model="selFritta" :options="optionsFritta" :reduce="option => option.value" placeholder=""
                        @search="handleSearch"></vue-select>
                    <span v-if="errors.fritta_id" class="text-red-500">
                        {{ errors.fritta_id[0] }}
                    </span>
                </div>
            </div>
        </div>

        <div class="flex flex-wrap mt-8 mb-6">
            <div class="w-full px-3">
                <SubmitFormButton btnText="Conferma" @submit="createTeam" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import TextInput from '../../components/global/TextInput.vue'
import SubmitFormButton from '../../components/global/SubmitFormButton.vue'
import axios from 'axios';
import { useRouter } from 'vue-router';
import $wal from '../../sweetalert2';

import VueSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

const router = useRouter()

let nome = ref(null)
let errors = ref([])


const itemPala = ref([])
const itemTeglia = ref([])
const itemGlutine = ref([])
const itemRomana = ref([])
const itemFritta = ref([])
const optionsPala = ref([]);
const optionsTeglia = ref([]);
const optionsGlutine = ref([]);
const optionsRomana = ref([]);
const optionsFritta = ref([]);

const selPala = ref(null)
const selTeglia = ref(null)
const selGlutine = ref(null)
const selRomana = ref(null)
const selFritta = ref(null)

const createTeam = async () => {
    errors.value = []

    let data = new FormData()

    data.append('nome_concorso', nome.value || '')
    data.append('pala_id', Number(selPala.value) || '')
    data.append('teglia_id', Number(selTeglia.value) || '')
    data.append('glutine_id', Number(selGlutine.value) || '')
    data.append('romana_id', Number(selRomana.value) || '')
    data.append('fritta_id', Number(selFritta.value) || '')

    try {
        await axios.post('api/crea-team', data)
        $wal.fire(
            'Nuovo team creato!',
            '',
            'success'
        )

        router.push('/teams/show')

    } catch (err) {
        errors.value = err.response.data.errors;
    }
}
async function fetchPala() {
    try {
        const { data } = await axios.get('/api/concorrenti-free-concorso/6')
        itemPala.value = data
        itemPala.value.forEach((item) => {
            optionsPala.value.push({
                value: item.id.toString(),
                label: `${item.cognome} ${item.nome} [${item.shortCode}]`
            });
        });
    } catch (error) {
        console.log(error);
    }
}
async function fetchTeglia() {
    try {
        const { data } = await axios.get('/api/concorrenti-free-concorso/5')
        itemTeglia.value = data
        itemTeglia.value.forEach((item) => {
            optionsTeglia.value.push({
                value: item.id.toString(),
                label: `${item.cognome} ${item.nome} [${item.shortCode}]`
            });
        });
    } catch (error) {
        console.log(error);
    }
}
async function fetchGlutine() {
    try {
        const { data } = await axios.get('/api/concorrenti-free-concorso/8')
        itemGlutine.value = data
        itemGlutine.value.forEach((item) => {
            optionsGlutine.value.push({
                value: item.id.toString(),
                label: `${item.cognome} ${item.nome} [${item.shortCode}]`
            });
        });
    } catch (error) {
        console.log(error);
    }
}
async function fetchRomana() {
    try {
        const { data } = await axios.get('/api/concorrenti-free-concorso/7')
        itemRomana.value = data
        itemRomana.value.forEach((item) => {
            optionsRomana.value.push({
                value: item.id.toString(),
                label: `${item.cognome} ${item.nome} [${item.shortCode}]`
            });
        });
    } catch (error) {
        console.log(error);
    }
}
async function fetchFritta() {
    try {
        const { data } = await axios.get('/api/concorrenti-free-concorso/9')
        itemFritta.value = data
        itemFritta.value.forEach((item) => {
            optionsFritta.value.push({
                value: item.id.toString(),
                label: `${item.cognome} ${item.nome} [${item.shortCode}]`
            });
        });
    } catch (error) {
        console.log(error);
    }
}

onMounted(() => {
    fetchPala();
    fetchTeglia();
    fetchGlutine();
    fetchRomana();
    fetchFritta();
})

</script>