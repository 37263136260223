<template>
    <TopNavigation />
    <div id="EditOrder" class="container max-w-4xl mx-auto pt-20 pb-20 px-6">

        <div class="text-gray-900 text-xl">Aggiungi dettaglio forno</div>
        <div class="bg-green-500 w-full h-1"></div>

        <div class="flex flex-wrap mt-4 mb-6">
            <div class="w-full md:w1/2 px-3 mb-3">
                <label class="block 
                                uppercase 
                                tracking-wide 
                                text-xs         
                                font-bold 
                                mb-2        
                                text-gray-100
                                text-gray-900
                            ">
                    Può gareggiare
                </label>
                <select
                    class="w-1/3 px-3 py-2 rounded-md bg-gray-50 border border-gray-300 text-sm shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                    v-model="gareggia" @change="handleChange">
                    <option value="1">Si</option>
                    <option value="0">No</option>
                </select>
            </div>
            <div v-if="trigger" class="flex flex-wrap">
            </div>
            <div v-else class="w-full md:w1/2 px-3 mb-3">
                <label class="block 
                                uppercase 
                                tracking-wide 
                                text-xs         
                                font-bold 
                                mb-2        
                                text-gray-100
                                text-gray-900
                            ">
                    N°. Forno
                </label>
                <select
                    class="w-1/3 px-3 py-2 rounded-md bg-gray-50 border border-gray-300 text-sm shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                    v-model="nr_forno">
                    <option value=""></option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                </select>
            </div>
            <div class="w-full px-3 mb-3">
                <TextInput label="Nota" placeholder="" v-model:input="nota" inputType="text"
                    :error="errors?.nota ? errors.nota[0] : ''" />
            </div>
        </div>
        <div class="flex flex-wrap mt-8 mb-6">
            <SubmitFormButton btnText="Conferma" @click="updateDetails" />
        </div>
    </div>
    <FooterSection />
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import axios from 'axios'
import TextInput from '../../components/global/TextInput.vue';
import SubmitFormButton from '../../components/global/SubmitFormButton.vue';
import $wal from '../../sweetalert2';
import TopNavigation from "../../components/structure/TopNavigation.vue";
import FooterSection from '../../components/structure/FooterSection.vue';

const router = useRouter()

const route = useRoute();
const id = ref(route.params.id);

let gareggia = ref(false)
let nr_forno = ref(null)
let nota = ref('')
let trigger = ref(false)
let errors = ref([])

watch(() => gareggia.value, () => {
    trigger.value = !trigger.value
})

onMounted(() => {
    fetchConcorso();
})

const updateDetails = async () => {
    errors.value = []

    let data = new FormData();

    data.append('id', id.value);
    data.append('gareggia', gareggia.value);
    data.append('nr_forno', nr_forno.value);
    data.append('nota', nota.value);

    try {
        await axios.post('api/forno/addFornoDetails?_method=PUT', data)

        $wal.fire(
            'Operazione riuscita!',
            'I dati sono stati correttamente salvati.',
            'success'
        )
        router.push(`/dashforno`)
        //router.push(query.value)
    } catch (err) {
        if (err.response) {
            errors.value = err.response.data.errors;
        } else {
            errors.value = err.message;
        }
        $wal.fire(
            'Errore!',
            'Si è verificato un problema in fase di salvataggio dei dati.',
            'error'
        )
    }
}

function handleChange() {
    console.log("Cambio: ", gareggia.value, gareggia.value == 1)
    if (trigger.value) {
        nota.value = '';
    }
    else {
        nr_forno.value = '';
    }
}

const fetchConcorso = async () => {
    try {

        console.log('ID Concorso_cliente: ', id.value);

        const response = await axios.get(`/api/cliente-concorso/concorso/${id.value}`);
        if (response.data.data) {
            gareggia.value = response.data.gareggia;
            trigger.value = gareggia.value;
            nr_forno.value = response.data.nr_forno;
            nota.value = response.data.nota;
        }
    } catch (error) {
        console.error(error);
    }
}

</script>