<template>
    <div id="" class="container max-w-4xl mx-auto pt-5 pb-20 px-6">
        <div class="flex justify-between items-center pt-1">
            <div class="text-gray-900 text-xl">
                Classifica Teams
            </div>
        </div>
        <div class="bg-green-500 w-full h-1 my-4"></div>
        <div v-if="classifica">
            <div class="scroll-container">
                <div class="table-wrapper">
                    <table>
                        <thead>
                            <tr>
                                <th style="width: 60px;" class="text-center"></th>
                                <th style="width: 60px;" class="text-right">Pos.</th>
                                <th style="width: 280px;" class="text-left">Nome Teams</th>
                                <th style="width: 150px;" class="text-right">Voto Totale</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(client, index) in classifica" :key="client.id"
                                :class="{ 'bg-even': index % 2 === 0 }">
                                <td style="width: 60px;" class="text-center">
                                    <router-link :to="{ name: 'DettaglioTeam', params: { id: client.id } }">
                                        <i class="fas fa-search fa-1x mr-4"></i>
                                    </router-link>
                                </td>
                                <td style="width: 60px;" class="text-right">{{ index + 1 }}</td>
                                <td style="width: 280px;" class="text-left">{{ client.nome_concorso }}</td>
                                <td style="width: 150px;" class="text-right">{{ client.voto_complessivo }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="w-full pl-4" v-else>
            Caricamento in corso...
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import axios from 'axios';

const classifica = ref(null);

const fetchClassifica = async () => {
    try {
        const response = await axios.get(`/api/teams/classifica`);
        classifica.value = response.data;
    } catch (error) {
        console.error(error);
    }
}

onMounted(() => {
    fetchClassifica();
});
</script>


<style>
.scroll-container {
    overflow-x: auto;
    width: 100%;
}

.table-wrapper {
    width: max-content;
    margin: 0 auto;
}

table {
    border-collapse: collapse;
    width: 100%;
}

th,
td {
    padding: 8px;
    text-align: left;
}

.bg-even {
    background-color: #f2f2f2;
}

.bg-green-500 {
    background-color: green;
}

.bg-red-500 {
    background-color: red;
}

.rounded-full {
    border-radius: 50%;
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination {
    display: flex;
}

.pagination button {
    border: 1px solid #ccc;
    padding: 8px 12px;
    margin: 0 4px;
    border-radius: 4px;
}

.pagination button:first-child {
    margin-left: 0;
}

.pagination button:last-child {
    margin-right: 0;
}
</style>