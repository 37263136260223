<template>
  <div id="Register" class="login-view">
    <div class="w-full p-6 flex justify-center items-center">
      <div class="w-full max-w-xs">

        <div class="bg-black p-8 shadow rounded mb-6 wrapper-box-login">
          <div class="mb-6 text-lg text-gray-100 font-thin">
            <img :src="require('@/assets/Logo-Campionato-Mondiale-Pizza-DOC.png')" class="logo-pizza" >
<!--             <img class="logo-pizza-login" :src="require('@/assets/Logo-Campionato-Mondiale-Pizza-DOC.png')" alt="">
 -->          </div>

          <div class="mb-4">
            <TextInput label="Email" :labelColor="false" placeholder="Email" v-model:input="email"
              inputType="email" :error="errors.email ? errors.email[0] : ''" id="email" />
          </div>

          <div class="mb-4">
            <TextInput label="Password" :labelColor="false" placeholder="password" v-model:input="password"
              inputType="password" :error="errors.password ? errors.password[0] : ''" id="password" />
          </div>
          <button class="
                            block
                            w-full
                            bg-green-500
                            text-white
                            text-center
                            rounded-sm
                            py-3
                            text-sm
                            tracking-wide
                            btn-standard                            
                        " type="submit" @click="login">
            Login
          </button>
          <span v-if="errors" class="text-red-500">
            {{ messaggio }}
          </span>
        </div>

        <p v-if="1 === 2" class="text-center text-md text-gray-900">
          Non hai ancora un account?
          <router-link to="register" class="text-blue-500 no-underline hover:underline">
            Registrati
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';
import { useUserStore } from '../store/user-store'
import TextInput from '../components/global/TextInput.vue';
import { useRouter } from 'vue-router'
import { setAuthHeader } from '../axios.js'

const router = useRouter()
const userStore = useUserStore()

let errors = ref([])
let messaggio = ref(null)
let email = ref(null)
let password = ref(null)

const login = async () => {
  errors.value = []

  try {
    let res = await axios.post('api/login', {
      email: email.value,
      password: password.value,
    });

    await userStore.setUserDetails(res)
    console.log("questo è il token appena ricevuto dal login", res.data.token);
    setAuthHeader(res.data.token);
    if (res.data.user.role === 'G') {
      router.push('/dashgiudice');
    }
    else if (res.data.user.role === 'A' || res.data.user.role === 'U') {
      router.push('/dashboard');
    }
    else if (res.data.user.role === 'H') {
      router.push('/dashassistenza')
    }
    else if (res.data.user.role === 'F') {
      router.push('/dashforno')
    }
  } catch (err) {
    messaggio.value = 'Errore autenticazione'    
    errors.value = err.response.data.errors
  }
}
</script>