<template>
    <div class="w-full bg-bluepizza text-gray-100 footer-container">
        
        <div class="
            container 
            mx-auto
            max-w-4xl
            h-full
            flex
            flex-wrap
            justify-between
            items-start
            text-sm
            p-6 
            pt-8
            pb-4"
        >
        <div>
            <img :src="require('@/assets/Logo-Campionato-Mondiale-Pizza-DOC.png')" class="mb-4 logo-pizza logo-pizza-footer" >
            <img :src="require('@/assets/Studioesse.png')" class="logo-studioesse" style=" height: 40px; ">
        </div>
            <div class="mb-4">
                <div class="mb-4">
                    Links
                </div>
                <router-link to="/dashboard" class="block mb-2">Home</router-link>
            </div>
            <div class="mb-4">
                <!-- <div class="mb-4">
                    About
                </div> -->
                <p class="mb-4 leading-normal">
                    <!-- 123 via Roma <br>
                    Napoli <br>
                    80100 -->
                    studioesse srl<br>
AVERSA | ROMA | MILANO<br>
72, via v. emanuele III, 81031 aversa (ce)<br>
PI 03358250615 / SDI M5UXCR1<br>
segreteria@studioesse.net<br>
0818904040<br><br>

<a href="https://www.studioesse.net" target="_blank">www.studioesse.net</a>

                </p>
                <p class="text-sm">0817703030</p>
            </div>
        </div>
                <div class="
            container 
            mx-auto
            max-w-4xl
            h-full
            flex
            flex-wrap
            justify-between
            items-center
            text-sm
            p-6"
        >
         &copy;2023 studioesse. Tutti i diritti riservati.
         <div class="pt-4 md:p-8 text-center md:text-right text-xs">
            <div class="no-underline hover:underline">Privacy Policy</div>
            <div class="no-underline hover:underline ml-4">Termini e Condizioni</div>
            <div class="no-underline hover:underline ml-4">Contatti</div>
         </div>
        </div>
    </div>
</template>