import { defineStore } from 'pinia'

export const useFiltersStore = defineStore('filters', {
  state: () => ({
    filtro_data: null,
    filtro_gara: null,
    filtro_orario: null,
    filtro_forno: null,
    filtro_testo: null,
  }),
  actions: {
    setFiltroData(filtro) {
      this.filtro_data = filtro
      localStorage.setItem('filtro_data', filtro)
    },
    setFiltroGara(filtro) {
      this.filtro_gara = filtro
      localStorage.setItem('filtro_gara', filtro)
    },
    setFiltroOrario(filtro) {
      this.filtro_orario = filtro
      localStorage.setItem('filtro_orario', filtro)
    },
    setFiltroForno(filtro) {
      this.filtro_forno = filtro
      localStorage.setItem('filtro_forno', filtro)
    },
    setFiltroTesto(filtro) {
      this.filtro_testo = filtro
      localStorage.setItem('filtro_testo', filtro)
    },
    loadFiltersFromLocalStorage() {
      this.filtro_data = localStorage.getItem('filtro_data')
      this.filtro_gara = localStorage.getItem('filtro_gara')
      this.filtro_orario = localStorage.getItem('filtro_orario')
      this.filtro_forno = localStorage.getItem('filtro_forno')
      this.filtro_testo = localStorage.getItem('filtro_testo')
    }
  }
})
