import axios from "axios";

const user = JSON.parse(window.localStorage.getItem('user'))

axios.defaults.baseURL = process.env.VUE_APP_API_URL

export function setAuthHeader(token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

}

if (user) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
}